<template>
  <v-container>
    <div
      class="d-flex align-space-between align-md-center justify-space-between flex-column flex-md-row mb-8"
    >
      <header-view :title="'Jogos'" class="mb-8 mb-md-0" />

      <section class="d-flex justify-end">
        <switch-show-type />

        <v-btn
          color="primary"
          class="black--text"
          depressed
          @click="createGame()"
        >
          Criar novo
        </v-btn>
      </section>
    </div>

    <filter-input
      class="mb-8"
      :dataFilters="require('@/assets/league/filters/games.json')"
    />

    <!-- grid mode -->
    <card-grid
      v-if="$store.getters.viewType ==='grid'"
      :items="_games"
      :loading="loading"
      @submit="editGame($event)"
    />

    <!-- list mode -->
    <custom-table
      v-else
      :headers="headers"
      :items="games"
      :loading="loading"
      @update:pagination="handlePagination($event)"
    >
      <template v-slot:[`item.game`]="{ item }">
        <v-list-item class="px-0">
          <v-list-item-avatar v-if="$vuetify.breakpoint.smAndUp" tile>
            <v-img
              :src="item.thumb || require('@/assets/league/empty-logo.png')"
            />
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{ item.name }}</v-list-item-title>
          </v-list-item-content>

          <v-list-item-avatar v-if="$vuetify.breakpoint.xsOnly" tile>
            <v-img
              :src="item.thumb || require('@/assets/league/empty-logo.png')"
            />
          </v-list-item-avatar>
        </v-list-item>
      </template>

      <template v-slot:[`item.plataform`]="{ item }">
        {{ item.plataform.toUpperCase() }}
      </template>

      <template v-slot:[`item.category`]="{ item }">
        {{ handleCategory(item.category) }}
      </template>

      <template v-slot:[`item.createdAt`]="{ item }">
        {{ formatDate(item.createdAt) }}
      </template>

      <template v-slot:[`item.updatedAt`]="{ item }">
        {{ formatDate(item.updatedAt) }}
      </template>

      <template v-slot:[`item.status`]="{ item }">
        {{ item.active ? "Ativa" : "Inativa" }}
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn class="rounded-lg" icon small @click="editGame(item.id)">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </template>
    </custom-table>

    <CreateGame ref="createGame" @success="getData()" />

    <EditGame ref="editGame" @success="getData()" />
  </v-container>
</template>

<script>
import { getGames } from "@/services/league/games.js";
import { formatDate } from "@/utils";
import CreateGame from "@/components/league/games/CreateGame.vue";
import EditGame from "@/components/league/games/EditGame.vue";

export default {
  data() {
    return {
      loading: true,
      
      headers: [
        {
          text: "Jogo",
          value: "game",
        },
        {
          text: "Plataforma",
          value: "plataform",
        },
        {
          text: "Categoria",
          value: "category",
        },
        {
          text: "Status",
          value: "status",
        },
        {
          text: "Data de criação",
          value: "createdAt",
        },
        {
          text: "Data de atualização",
          value: "updatedAt",
        },
        {
          value: "actions",
        },
      ],
      games: [],
    };
  },

  components: {
    CreateGame,
    EditGame,
  },

  beforeMount() {
    this.getData();
  },

  watch: {
    ["$route.query"](value) {
      this.handleQuery(value);
    },
  },

  computed: {
    _games() {
      return this.games.map((e) => {
        return {
          id: e.id,
          image: e.thumb,
          title: e.name,
          subtitle: this.handleCategory(e.category),
          icon: this.handleIcon(e.plataform),
          inactive: !e.active,
        };
      });
    },
  },

  methods: {
    async getData(filter = {}) {
      try {
        this.loading = true;

        const payload = {
          page: 0,
          size: 99,
          ...filter,
        };

        await getGames(payload).then((res) => {
          this.games = res.data;
        });
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    handleIcon(value) {
      switch (value) {
        case "mobile":
          return "mdi-cellphone";
        case "pc":
          return "mdi-desktop-tower-monitor";
        case "console":
          return "mdi-gamepad-variant";
      }
    },

    handleCategory(value) {
      switch (value) {
        case "fps":
          return "FPS";
        case "moba":
          return "MOBA";
        case "battle royale":
          return "Battle Royale";
        case "strategy":
          return "Estratégia";
        case "sport":
          return "Esporte";
        default:
          return "Outro";
      }
    },

    createGame() {
      this.$refs.createGame.handleDialog();
    },

    editGame(event) {
      const element = this.games.find((e) => e.id === event);
      if (!!element) this.$refs.editGame.handleDialog(element);
    },

    handleQuery(value) {
      this.loading = true;
      this.getData(value);
    },

    formatDate,
  },
};
</script>

<style>
.inactive {
  opacity: 0.5;
}

.zoom {
  transition: transform 0.25s !important;
}

.zoom:hover {
  transform: scale(1.025);
}
</style>
