import { request } from "./index";

export const getGames = (data) =>
  request("get", "/games", {
    params: data,
  });

export const createGame = (data) => request("post", `/games`, data);

export const updateGame = (data) =>
  request("put", `/games/${data.id}`, data.body);
