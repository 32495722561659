<template>
  <custom-dialog ref="dialog" title="Editar jogo" @submit="submit()">
    <v-form v-model="valid" ref="form">
      <div @keypress.enter.prevent="submit()">
        <avatar-pick
          v-model="file"
          ref="thumb"
          :current="form.thumb"
          :required="false"
        />

        <custom-input
          v-model="form.name"
          ref="name"
          label="Nome"
          placeholder="Informe o nome do jogo"
        />

        <custom-select
          v-model="form.plataform"
          ref="plataform"
          label="Plataforma"
          placeholder="Selecione a plataforma"
          :items="plataforms"
        />

        <custom-select
          v-model="form.category"
          ref="category"
          label="Categoria"
          placeholder="Selecione a categoria"
          :items="categories"
        />

        <v-switch
          v-model="form.active"
          class="my-0 ml-2"
          :label="form.active ? 'Ativo' : 'Inativo'"
          inset
        />
      </div>
    </v-form>

    <loader-hover v-if="loading" />
  </custom-dialog>
</template>

<script>
import { updateGame } from "@/services/league/games.js";
import { fileUpload } from "@/services/league/files.js";
import { displayAlert } from "@/utils";

export default {
  data() {
    return {
      loading: false,
      valid: false,
      plataforms: [
        {
          text: "PC",
          value: "pc",
        },
        {
          text: "Console",
          value: "console",
        },
        {
          text: "Mobile",
          value: "mobile",
        },
      ],
      categories: [
        {
          text: "FPS",
          value: "fps",
        },
        {
          text: "MOBA",
          value: "moba",
        },
        {
          text: "Esportes",
          value: "sport",
        },
        {
          text: "Battle Royale",
          value: "battle royale",
        },
        {
          text: "Estratégia",
          value: "strategy",
        },
      ],
      id: "",
      file: null,
      form: {
        thumb: null,
        name: "",
        plataform: "",
        category: "",
        active: true,
      },
    };
  },

  methods: {
    async submit() {
      if (!this.$refs.form.validate() || !this.$refs.thumb.validate()) return;

      try {
        this.loading = true;

        if (!!this.file) {
          const formData = new FormData();
          formData.append("file", this.file);

          await fileUpload(formData).then((res) => {
            if (res.files.length <= 0)
              throw new Error("Falha ao salvar imagem");

            this.form.thumb = res.files[0].file_url;
          });
        }

        const payload = {
          id: this.id,
          body: this.form,
        };

        await updateGame(payload).then(() => {
          this.$emit("success");
          this.close();
        });
      } catch (err) {
        this.displayAlert(err.data.message, 1);
      } finally {
        this.loading = false;
      }
    },

    async handleDialog(event) {
      this.id = event.id;

      await this.$refs.dialog.openDialog();
      this.startForm(event);
    },

    startForm(event) {
      this.$refs.thumb.reset();
      this.$refs.name.handleInput(event.name);
      this.$refs.plataform.handleInput(event.plataform);
      this.$refs.category.handleInput(event.category);
      this.form.thumb = event.thumb;
      this.form.active = event.active;
    },

    close() {
      this.$refs.dialog.closeDialog();
    },

    displayAlert,
  },
};
</script>

<style></style>
